import * as React from "react"
import BookingOnline from "../../components/BookingOnline"
import ExploreBox from "../../components/ExploreBox"
import ExploreText from "../../components/ExploreText"
import Layout from "../../components/Layout"
import BackToTop from "../../components/BackToTop"
import Aos from "aos"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
const Activities = ({ data }) => {
  const { title, _rawDescription, activities } =
    data.sanityPages?.activitiesPage
  React.useEffect(() => {
    Aos.init()
  })
  return (
    <>
      <Seo
        title={"Activity Page"}
        description={
          "This page shows that customers can eplore new place near to hotel."
        }
      />
      <Layout>
        <div className="header_bottom special-_5">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="explore__section activity">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="explore_block">
                  <ExploreText
                    title={title}
                    description={_rawDescription}
                    index={0}
                  />
                </div>
                <div className="row">
                  {activities.map((item, index) => (
                    <div className="col-md-6 col-lg-4">
                      <ExploreBox
                        press
                        activityBox
                        index={index}
                        image={item?.thumbnail?.asset.gatsbyImageData}
                        title={item.title}
                        pageUrl={item?.link?.path}
                        // icon={icon}
                        pageClass={"activity__thumb__bg"}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Activities

export const activityData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      activitiesPage {
        title
        _rawDescription
        activities {
          title
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          link {
            label
            path
            type
          }
        }
      }
    }
  }
`
